<template>
  <div>
    <v-card flat color="accent rounded-0">
      <v-row>
        <v-col md="2" v-if="cycles.cycle_avg">
          <box-regular :cyclesAvg="cycles.cycle_avg.toString()"></box-regular>
        </v-col>
      </v-row>
    </v-card>

    <div class="common-container">
      <v-form>
        <v-row
          class="mr-4 mt-4 d-inline-flex justify-space-between align-center"
          style="width: 100%"
        >
          <v-col cols="12" md="2" v-if="locations">
            <v-text-field
              type="number"
              v-model="cycleValue"
              :label="$tc(`cycle.label_cycle`, cycleValue)"
              :prefix="$tc(`cycle.prefix_cycle`, cycleValue)"
              @input="handleChange"
              :rules="inputNum"
              outlined
              solo
              dense
              flat
              clearable
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="2" v-if="locations">
            <v-select
              :items="locations"
              :label="$t(`cycle.location`)"
              v-model="selectedLocation"
              :item-text="`description`"
              :item-value="`id`"
              @change="handleChange"
              dense
              outlined
            ></v-select>
          </v-col>

          <v-col cols="12" md="2" v-if="status">
            <v-select
              :items="status"
              label="Status"
              v-model="selectedStatus"
              :item-text="`name`"
              :item-value="`value`"
              @change="handleChange"
              dense
              outlined
            ></v-select>
          </v-col>

          <v-col cols="12" md="2" v-if="status">
            <v-select
              :items="killFilter"
              :label="$t('alarm.filter_kill')"
              v-model="isFilterKill"
              :item-text="`name`"
              :item-value="`value`"
              @change="handleChange"
              dense
              outlined
              clearable
            ></v-select>
          </v-col>

          <v-col cols="12" md="2"> </v-col>
          <v-col cols="12" md="2" class="d-flex"> </v-col>
        </v-row>
      </v-form>

      <h2 v-if="cyclesTable">{{ $tc("cycle.pallet", cyclesTable.length) }}</h2>
      <div v-if="cyclesTable">
        <v-data-table
          :headers="headers"
          :items="cyclesTable"
          :items-per-page="5"
          class="elevation-0"
          :item-class="kill_classes"
          :hide-default-footer="cyclesTable.length < 5"
        >
          <template v-slot:[`item.box_id`]="props">
            <router-link
              :to="{
                name: 'box-detail',
                params: { id: props.item.box_id },
              }"
            >
              {{ props.item.box_id }}
            </router-link>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn text small>
              <img
                style="cursor: pointer"
                src="@/assets/images/Icon-download.svg"
                @click="downloadCsv(item.box_id)"
              />
            </v-btn>
            <v-btn
              text
              color="error"
              class="mx-3"
              small
              @click="confirmDeleteItem(item)"
            >
              <v-icon color="error">mdi-delete-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </div>

    <confirm-dialog ref="confirm"></confirm-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "@/i18n";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";

export default {
  name: "cycle",
  components: { ConfirmDialog },
  async mounted() {
    await this.fetchCycles();
    await this.fetchLocation();
    await this.fetchCyclesTable({});
  },
  data() {
    return {
      selectedLocation: null,
      cycleValue: null,
      status: [
        { name: i18n.t("cycle.status.positioned"), value: 1 },
        { name: i18n.t("cycle.status.in_transit"), value: 2 },
      ],
      selectedStatus: null,
      killFilter: [
        { name: i18n.t("common.true"), value: "True" },
        { name: i18n.t("common.false"), value: "False" },
      ],
      isFilterKill: null,
      params: {},
      headers: [
        {
          text: i18n.t("cycle.table.id"),
          align: "start",
          sortable: false,
          value: "box_id",
        },
        { text: i18n.t("cycle.table.gtin"), value: "box" },
        { text: i18n.t("cycle.table.area"), value: "area" },
        { text: i18n.t("cycle.table.location"), value: "location" },
        { text: i18n.t("cycle.table.date"), value: "date" },
        { text: i18n.t("cycle.table.cycle"), value: "cycles" },
        { text: i18n.tc("label.action", 2), value: "action" },
      ],
    };
  },
  methods: {
    ...mapActions({
      fetchLocation: "dashboard/fetchLocation",
      fetchTransits: "dashboard/fetchTransits",
      fetchCycles: "cycles/fetchCycles",
      fetchCyclesTable: "cycles/fetchCyclesTable",
      downloadCsv: "cycles/downloadCsv",
      deleteCycle: "cycles/deleteCycle",
    }),
    kill_classes(item) {
      console.log("item.kill: ", item.kill);
      if (item.kill) {
        return "kill-class";
      }
    },
    handleChange() {
      this.createParams();
      this.fetchCyclesTable(this.params);
    },
    async confirmDeleteItem(item) {
      if (
        await this.$refs.confirm.open(
          `${i18n.t("label.delete")}`,
          `${i18n.t("user.delete-message", { item: item.box })}`,
          this.options
        )
      ) {
        this.deleteRecord(item.box);
      }
    },
    deleteRecord(id) {
      this.deleteCycle(id)
        .then(() => {
          this.fetchCyclesTable();
        })
        .catch((err) => console.log("Delete user error :", err));
    },
    createParams() {
      const params = {};
      params["cycles"] = this.cycleValue;
      params["area1__type"] = this.selectedStatus;
      params["area1__location__id"] = this.selectedLocation;
      params["box__kill"] = this.isFilterKill;
      this.params = params;
    },
  },
  computed: {
    ...mapGetters({
      locations: "dashboard/getLocations",
      cycles: "cycles/getCycles",
      cyclesTable: "cycles/getCyclesTable",
    }),
  },
};
</script>

<style></style>
